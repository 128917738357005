(function ($, Drupal) {
  Drupal.behaviors.siteFooterV1 = {
    elems: {},
    attach: function (context) {
      // Get our variables
      var self = this;

      self.elems.$module = $('.js-site-footer--v1', context);
      self.elems.$countryList = $('.js-footer-country-list', self.elems.$module);
      self.elems.$countrySelectbox = $('.js-footer-country-select', self.elems.$module);
      self.elems.$stickyElements = $('.js-footer-sticky', self.elems.$module);

      // Perform our functions
      self.countryList();
      self.stickyElements();
    },

    countryList: function () {
      var self = this;

      // Turn our menu into a select box
      self.elems.$countryList.find('.menu li').each(function () {
        var href = $(this).find('a').attr('href');
        var text = $(this).text();
        var option = '<option value="' + href + '">' + text + '</option>';

        self.elems.$countrySelectbox.append(option);
      });

      // Initiate
      self.elems.$countrySelectbox.trigger('select.enabled');

      self.elems.$countrySelectbox.on('change', function (event) {
        // When a country is selected, open it in a new window
        var urlToOpen = $(this).val();
        var win = window.open(urlToOpen, '_blank');
      });
    },

    stickyElements: function () {
      var self = this;

      self.elems.$stickyElements.on('mouseenter.openStickyElement click.openStickyElement mouseleave.openStickyElement focusin.openStickyElement focusout.openStickyElement', function (event) {
        if ($('body').hasClass('device-mobile')) {
          event.preventDefault();
          if (event.type === 'mouseenter' || event.type === 'focusin') {
            var stickyLink = $(this).find('a').attr('href');

            $(this).find('.js-footer-sticky-reveal').stop().slideUp(200);
            if (stickyLink) {
              setTimeout(function () {
                location.href = stickyLink;
              }, 2000);
            }
          } else {
            $(this).find('.js-footer-sticky-reveal').stop().slideDown(200);
          }
        } else {
          if (event.type === 'mouseleave' || event.type === 'focusout') {
            $(this).find('.js-footer-sticky-reveal').stop().slideUp(200);
          } else {
            $(this).find('.js-footer-sticky-reveal').stop().slideDown(200);
          }
        }
      });
    }
  };
})(jQuery, Drupal);
